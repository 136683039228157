import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  user;

  constructor(private router: Router,
              private alertController: AlertController,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.getUser();
  }

  async getUser() {
    this.user = await this.authService.getUser();
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Atenção',
      message: 'Deseja realmente <strong>sair</strong>?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Sim',
          handler: () => {
            this.authService.clearAuthorizationToken();
            this.authService.setUser(null);
            this.authService.setToken(null);
            this.router.navigate(['/login']);
          }
        }
      ]
    });

    await alert.present();
  }

}
