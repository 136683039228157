import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UsuarioService } from '../../shared/services/usuario.service';
import { ModalController } from '@ionic/angular';
import { Usuario } from '../../shared/models/usuario.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Aplicativo } from 'src/app/shared/models/aplicativo.model';


@Component({
  selector: 'app-modal-usuarios-vinculados',
  templateUrl: './modal-usuarios-vinculados.page.html',
  styleUrls: ['./modal-usuarios-vinculados.page.scss'],
})
export class ModalUsuariosVinculadosPage implements OnInit {

  @Input() idApp: number;
  @Input() idUsuarios: Array<number>;
  usuarios: Array<Usuario>;
  error = false;
  id: string;
  app: Aplicativo;
  existeUsuarios = false;

  form: FormGroup = new FormGroup({
    tecnologia: new FormControl(''),
    versao: new FormControl(''),
    ativo: new FormControl(false),
    forcaAtualizacao: new FormControl(false)
  });

  constructor(private usuarioService: UsuarioService,
    private modalController: ModalController,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.getUsuarios();
  }

  getUsuarios() {
    this.error = false;
    this.usuarioService.filtrar(this.getFilters()).subscribe(async (usuarios: any) => {
      this.error = false;
      this.usuarios = usuarios.content;
    },
      (error) => {
        this.error = true;
        this.toastService.toastError(error.error && error.error.mensagem ? error.error.mensagem : 'Ocorreu um erro inesperado, por favor tente mais tarde');
      })
  }

  getFilters() {
    const filters: any = {};
    if (this.idApp) {
      filters.idAplicativo = this.idApp;
    }
    return filters;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  refreshModal(){
    this.getUsuarios();
  }

}
