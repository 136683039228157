import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {Mensagem} from '../models/mensagem.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MensagemService extends AbstractService {

  constructor(
    public http: HttpClient) {
    super('mensagem', http);
  }

  //filtrar
  listarMensagens(page, size, idAplicativo, filter: any){
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('size', size);
    if (filter.situacao) {
      params = params.set('situacao', filter.situacao);
    }
    if (filter.titulo) {
      params = params.set('titulo', filter.titulo);
    }
    return this.filterByParam(params, `?idAplicativo=${idAplicativo}`);
  }
    
  getMensagem(idAplicativo: number, idMensagem: number) {
    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/mensagem/${idAplicativo}/${idMensagem}`);
  }

  public buscarPorApp(idAplicativo: string): Observable<Mensagem[]> {
    return this.getAll(`mensagem?idAplicativo=${idAplicativo}`);
  }

}
