import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Aplicativo} from '../models/aplicativo.model';

@Injectable({
  providedIn: 'root'
})
export class AplicativoService extends AbstractService {

  constructor(public http: HttpClient) {
    super('aplicativo', http);
  }

  salvar(form: Aplicativo, usuarios, idAplicativo = null) {
    const formData = new FormData();
    if (form.nome) {
      formData.set('nome', form.nome);
    }
    if (form.appBundle) {
      formData.set('appBundle', form.appBundle);
    }
    if (form.icone) {
      formData.set('icone', form.icone);
    }
    if (form.chavePrivada) {
      formData.set('chavePrivada', form.chavePrivada);
    }
    if (usuarios) {
      formData.set('usuarios', usuarios);
    }
    if (form.ativo) {
      formData.set('ativo', JSON.stringify(form.ativo));
    }
    if (form.forcaAtualizacao) {
      formData.set('forcaAtualizacao', JSON.stringify(form.forcaAtualizacao));
    }
    if (form.plataformas && form.plataformas.versaoIos) {
      formData.set('versaoIos', form.plataformas.versaoIos);
    }
    if (form.plataformas && form.plataformas.versaoAndroid) {
      formData.set('versaoAndroid', form.plataformas.versaoAndroid);
    }
    if (form.plataformas && form.plataformas.versaoPwa) {
      formData.set('versaoPwa', form.plataformas.versaoPwa);
    }
    if (form.superApp) {
      formData.set('superApp', JSON.stringify(form.superApp));
    }
    if (form.idAplicativoPai) {
      formData.set('idAplicativoPai', JSON.stringify(form.idAplicativoPai));
    }
    if (form.url) {
      formData.set('url', form.url);
    }

    if (idAplicativo) {
      formData.set('id', idAplicativo);
      return this.putFormData(formData);
    } else {
      return this.postFormData(formData);
    }
  }
}
